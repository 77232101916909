<template>
  <div style="width: 100% ;padding: 10px">
    <span v-if="processList.length === 0">您尚未被分配加工任务</span>
    <!--电脑页面-->
    <div v-if="!isPhone">
      <div style="margin: 10px 0">
        <el-select v-model="process" filterable :placeholder="processList[0]" @change="select(process),currentPage4 = 1">
          <el-option
              v-for="item in processList"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
        <el-input style="width: 200px" v-model="selectKey"></el-input>
        <el-button @click="getTableDateShow">筛选</el-button>
        <el-button @click="selectKey='',getTableDateShow()">重置</el-button>
        <el-switch
            v-model="showZero"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="getTableDateShow">
        </el-switch>
        <span>{{ showZero ? "显示所有" : "筛选未填报工件" }}</span>
      </div>
      <el-table
          :data="tableDataShow"
          border>
        <el-table-column type="expand" width="50px">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item><span>
            </span>
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>废件信息</span>
                    <el-button style="float: right; padding: 3px 0" type="text" @click="feijianAdd(props.row)">添加</el-button>
                  </div>
                  <div v-for="(item,index) in props.row.feijian" :key="index" class="text item">
                    {{'废件信息'+(index+1) +": ["+ props.row.feijian[index].scrapLabels +"]"+props.row.feijian[index].scrapNumbers+"件，   备注: "+props.row.feijian[index].scrapNotes }}
                    <!--                  <el-button @click="feijianUp(scope.row)" type="text" size="middle">{{ '   编辑  ' }}</el-button>
                                      <el-button @click="feijianDel(scope.row)" type="text" size="middle">{{ '删除' }}</el-button>-->
                    <el-button style="float: right; padding: 3px 0" type="text" @click="feijianDel(props.row.feijian[index].noid)">删除</el-button>
                  </div>
                </el-card>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
            prop="id"
            v-if="false"
            label="id">
        </el-table-column>
        <el-table-column
            prop="tuzhihao"
            width="200px"
            label="图纸号">
        </el-table-column>
        <el-table-column
            prop="xinghao"
            width="200px"
            label="型号">
        </el-table-column>
        <el-table-column
            prop="gongxuName"
            v-if="false"
            label="工序">
        </el-table-column>
        <el-table-column
            prop="gongxuTap"
            width="200px"
            label="工序标签">
          <template slot-scope="scope">
            <el-button @click="up(scope.row)" type="text" size="small">{{scope.row.gongxuTap}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
            prop="totalnum"
            label="投产数量">
        </el-table-column>
        <el-table-column
            prop="mubuiaonum"
            label="目标数量">
        </el-table-column>
        <el-table-column
            prop="num"
            width="100px"
            label="加工数量">
          <template slot-scope="scope">
            <el-button @click="up(scope.row)" type="text" size="small">{{scope.row.num}}</el-button>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="tab"-->
<!--            width="250px"-->
<!--            label="任务提示">-->
<!--        </el-table-column>-->
        <el-table-column
            prop="finishDate"
            width="250px"
            label="完工期限">
          <template slot-scope="scope">
            <el-date-picker
                v-model="scope.row.finishDate"
                v-if="scope.row.finishDate"
                type="date"
                disabled
                placeholder="选择日期">
            </el-date-picker>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin: 10px 0">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>

      <!--  弹窗  -->
      <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <el-form :model="form" label-width="80px">
          <el-form-item v-if="false" label="工件id">
            <el-input v-model="form.gongjianid" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item v-if="false" label="加工人">
            <el-input v-model="form.id" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item v-if="false" label="工序">
            <el-input v-model="form.gongxuName" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item label="加工数量">
            <el-input v-model="num" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item v-if="tapList.length !== 0" label="工件标签">
            <el-select v-model="form.tap" placeholder="请选择" @change="$forceUpdate();">
              <el-option
                  v-for="item in tapList"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <cmdb-progress ref="cmdbProgress" v-if="dialogVisible" :affiliation-id="form.gongjianid" :progress="process" affiliation-type="workpiece" :update-inventory="false"/>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save(num)">确 定</el-button>
        </span>
      </el-dialog>
      <!--  废件弹窗  -->
      <el-dialog :visible.sync="dialogVisible2" width="50%" :before-close="handleClose">
        <el-form :model="feijianform" label-width="80px">
          <el-form-item label="废件标签">
            <el-radio-group v-model="feijianform.scrapLabels">
              <el-radio label="自废"></el-radio>
              <el-radio label="料废"></el-radio>
              <el-radio label="其他"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="废件数">
            <el-input v-model="feijianform.scrapNumbers" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item v-if="process == '检查' && feijianform.scrapLabels != '料废'" label="加工人">
            <el-select v-model="feijianform.yuangongid" placeholder="请选择">
              <el-option
                  v-for="item in personlist"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="process == '检查'" label="工序">
            <el-select v-model="feijianform.gongxuName" placeholder="请选择">
              <el-option
                  v-for="item in processAllList"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="feijianform.scrapNotes" style="width: 90%"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="feijianSave">确 定</el-button>
      </span>
      </el-dialog>
    </div>
    <!--手机页面-->
    <div v-if="isPhone">
      <div style="margin: 10px 0">
        <el-row>
          <el-select v-model="process" filterable :placeholder="processList[0]" @change="select(process),currentPage4 = 1">
            <el-option
                v-for="item in processList"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-row>
        <el-row>
          <el-input style="width: 200px" v-model="selectKey"></el-input>
        </el-row>
        <el-row>
          <el-button @click="getTableDateShow">筛选</el-button>
          <el-button @click="selectKey='',getTableDateShow()">重置</el-button>
        </el-row>
        <el-row>
          <el-switch
              v-model="showZero"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="getTableDateShow">
          </el-switch>
          <span>{{ showZero ? "显示所有" : "筛选未填报工件" }}</span>
        </el-row>
      </div>
      <div>
        <el-collapse v-model="activeName" accordion>

          <el-collapse-item v-for="item in tableDataShow" :key="item.id" :title="item.xinghao" :name="item.id">
            <el-row :style="{width:(screenWidth*0.85)+'px'}">
              <el-col :style="{width:(screenWidth*0.3)+'px'}">
                图纸号
              </el-col>
              <el-col :style="{width:(screenWidth*0.5)+'px'}">
                {{item.tuzhihao}}
              </el-col>
            </el-row>
            <el-row v-if="false" :style="{width:(screenWidth*0.85)+'px'}">
              <el-col :style="{width:(screenWidth*0.3)+'px'}">
                工序名称
              </el-col>
              <el-col :style="{width:(screenWidth*0.5)+'px'}">
                {{item.gongxuName}}
              </el-col>
            </el-row>
            <el-row :style="{width:(screenWidth*0.85)+'px'}">
              <el-col :style="{width:(screenWidth*0.3)+'px'}">
                工序标签
              </el-col>
              <el-col :style="{width:(screenWidth*0.5)+'px'}">
                <el-button @click="up(item)" type="text" size="small">{{item.gongxuTap}}</el-button>
              </el-col>
            </el-row>
            <el-row :style="{width:(screenWidth*0.85)+'px'}">
              <el-col :style="{width:(screenWidth*0.3)+'px'}">
                投产数量
              </el-col>
              <el-col :style="{width:(screenWidth*0.5)+'px'}">
                {{item.totalnum}}
              </el-col>
            </el-row>
            <el-row :style="{width:(screenWidth*0.85)+'px'}">
              <el-col :style="{width:(screenWidth*0.3)+'px'}">
                目标数量
              </el-col>
              <el-col :style="{width:(screenWidth*0.5)+'px'}">
                {{item.mubuiaonum}}
              </el-col>
            </el-row>
            <el-row :style="{width:(screenWidth*0.85)+'px'}">
              <el-col :style="{width:(screenWidth*0.3)+'px'}">
                加工数量
              </el-col>
              <el-col :style="{width:(screenWidth*0.5)+'px'}">
                <el-button @click="up(item)" type="text" size="small">{{item.num}}</el-button>
              </el-col>
            </el-row>
            <el-row :style="{width:(screenWidth*0.85)+'px'}">
              <el-col :style="{width:(screenWidth*0.3)+'px'}">
                完工期限
              </el-col>
              <el-col :style="{width:(screenWidth*0.5)+'px'}">
                <el-date-picker
                    v-model="item.finishDate"
                    v-if="item.finishDate"
                    type="date"
                    disabled
                    placeholder="选择日期">
                </el-date-picker>
              </el-col>
            </el-row>
            <el-row :style="{width:(screenWidth*0.85)+'px'}">
              <el-card :style="{width:(screenWidth*0.65)+'px'}">
                <div slot="header" class="clearfix">
                  <span>废件信息</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="feijianAdd(item)">添加</el-button>
                </div>
                <div v-for="(childItem,childIndex) in item.feijian" :key="childIndex" class="text item">
                  {{'废件信息'+(childIndex+1) +": ["+ item.feijian[childIndex].scrapLabels +"]"+item.feijian[childIndex].scrapNumbers+"件，   备注: "+item.feijian[childIndex].scrapNotes }}
                  <!--                  <el-button @click="feijianUp(scope.row)" type="text" size="middle">{{ '   编辑  ' }}</el-button>
                                    <el-button @click="feijianDel(scope.row)" type="text" size="middle">{{ '删除' }}</el-button>-->
                  <el-button style="float: right; padding: 3px 0" type="text" @click="feijianDel(item.feijian[childIndex].noid)">删除</el-button>
                </div>
              </el-card>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div style="margin: 10px 0">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>

      <!--  弹窗  -->
      <el-dialog :visible.sync="dialogVisible" :width="isPhone ? '90%' : '50%'" :before-close="handleClose">
        <el-form :model="form" label-width="80px">
          <el-form-item v-if="false" label="工件id">
            <el-input v-model="form.gongjianid" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item v-if="false" label="加工人">
            <el-input v-model="form.id" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item v-if="false" label="工序">
            <el-input v-model="form.gongxuName" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item label="加工数量">
            <el-input v-model="num" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item v-if="tapList.length !== 0" label="工件标签">
            <el-select v-model="form.tap" placeholder="请选择" @change="$forceUpdate();">
              <el-option
                  v-for="item in tapList"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <cmdb-progress ref="cmdbProgress" v-if="dialogVisible" :affiliation-id="form.gongjianid" :progress="process" affiliation-type="workpiece" :update-inventory="false" :width="isPhone ? (screenWidth*0.7) : 0"/>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save(num)">确 定</el-button>
        </span>
      </el-dialog>
      <!--  废件弹窗  -->
      <el-dialog :visible.sync="dialogVisible2" :width="isPhone ? '90%' : '50%'" :before-close="handleClose">
        <el-form :model="feijianform" label-width="80px">
          <el-form-item label="废件标签">
            <el-radio-group v-model="feijianform.scrapLabels">
              <el-radio label="自废"></el-radio>
              <el-radio label="料废"></el-radio>
              <el-radio label="其他"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="废件数">
            <el-input v-model="feijianform.scrapNumbers" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item v-if="process == '检查' && feijianform.scrapLabels != '料废'" label="加工人">
            <el-select v-model="feijianform.yuangongid" placeholder="请选择">
              <el-option
                  v-for="item in personlist"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="process == '检查'" label="工序">
            <el-select v-model="feijianform.gongxuName" placeholder="请选择">
              <el-option
                  v-for="item in processAllList"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="feijianform.scrapNotes" style="width: 90%"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="feijianSave">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import request from "@/utils/request";
import cmdbProgress from "@/views/element/cmdb_progress";

export default {
  components:{cmdbProgress},
  data() {
    return {
      screenWidth: document.body.clientWidth,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      search:'',
      process:'',
      processList:[],
      tapList:[],
      currentPage4:1,
      size:10,
      total:0,
      num:0,
      form:{},
      feijianform:{},
      resList:['自废','料废','其他'],
      tableData: [],
      tableDataShow: [],
      dialogVisible: false,
      dialogVisible2: false,
      personlist: [],
      processAllList: [],
      showZero: false,
      selectKey: "",
      isPhone:false,
      activeName: '',
    }
  },
  //加载表格
  mounted() {
    this.selectProcess()
    this.getPersonlist()
    this.selectAllProcess()
    let that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch:{
    screenWidth: {
      immediate: true,
      handler: function (newVal){
        this.getIsPhone(newVal)
      }
    },
  },
  methods:{
    getIsPhone(newVal){
      if (newVal<800){
        this.isPhone = true
      } else {
        this.isPhone = false
      }
    },
    up(row){
      this.form.gongxu = row.gongxuName;
      this.form.id = this.user.id;
      this.num = row.num;
      this.form.tap = row.gongxuTap;
      this.form.gongjianid = row.id;
      this.dialogVisible = true
    },
    //查询工序
    selectAllProcess(){
      request.post("/getAllPr").then(res =>{
        this.processAllList = res.data
      })
    },
    //查询
    getPersonlist(){
      request.post("/getPersonlist").then(res =>{
        this.personlist = res.data
      })
    },
    //编辑表单回显
    feijianAdd(row){
      this.dialogVisible2 = true
      this.feijianform.gongjianid = row.id;
      this.feijianform.gongxu = this.process;
      this.feijianform.yuangongid = this.user.id;
    },
    feijianDel(id){
      request.post("/errorDel", id).then(res => {
        if (res.code == "200") {
          this.$notify({
            title: '成功',
            message: '填报成功',
            type: 'success'
          });
          this.select(this.process)
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    feijianSave(){
      if (this.feijianform){
        let err = ""
        if (this.feijianform.scrapLabels === undefined || this.feijianform.scrapLabels === null || this.feijianform.scrapLabels === ""){
          err += (err===""?"":",")+"废件标签"
        }
        if ((this.feijianform.yuangongid === undefined || this.feijianform.yuangongid === null || this.feijianform.yuangongid === "") && this.feijianform.scrapLabels!=="料废"){
          err += (err===""?"":",")+"加工人"
        }
        if (this.feijianform.scrapNumbers === undefined || this.feijianform.scrapNumbers === null || this.feijianform.scrapNumbers === "" || this.feijianform.scrapNumbers === 0){
          err += (err===""?"":",")+"废件数"
        }
        if (err !== ""){
          this.$notify.error({
            title: '错误',
            message: '请填写'+err
          });
          return
        }
      }
      if (process == '检查'){
        request.post("/errorintByInspector", this.feijianform).then(res => {
          if (res.code == "200") {
            this.$notify({
              title: '成功',
              message: '填报成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible2 = false
            this.feijianform = {}
            this.select(this.process)
          } else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }else {
        request.post("/errorint", this.feijianform).then(res => {
          if (res.code == "200") {
            this.$notify({
              title: '成功',
              message: '填报成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible2 = false
            this.feijianform = {}
            this.select(this.process)
          } else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }
    },
    //保存
    save(num) {
      if(this.$refs.cmdbProgress.saveOut()){
        if (this.tapList.length !== 0 && (this.form.tap == null || this.form.tap === "无")){
          this.$notify.error({
            title: '错误',
            message: '请选择工序标签'
          });
        } else {
          this.form.num = num;
          request.post("/ProcessingFillIn", this.form).then(res => {
            if (res.code == "200") {
              this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success'
              });
              //this.tableData.push(this.form)
              this.dialogVisible = false
              this.form = {}
              this.select(this.process)
            } else {
              this.$notify.error({
                title: '错误',
                message: res.code + ":" + res.msg
              });
            }
          })
        }
      }

    },
    //编辑
    /*update() {
      this.form.classList = this.form.classList.toString()
      request.post("/connect/update", this.form).then(res => {
        if (res.code == "200") {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          this.dialogVisible = false
          this.form = {}
          request.post("/connect/select").then(res => {
            this.tableData = res.data
          })
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },*/
    //查询工序
    selectProcess(){
      request.post("/getPr",this.user.id).then(res =>{
        if (res.data.length > 0){
          this.processList = res.data
          this.process = res.data[0]
          this.getPrTap(res.data[0])
          this.select(res.data[0])
        } else {
          this.$notify.error({
            message: '您尚未被分配加工任务'
          });
        }
      })
    },
    getPrTap(pre){
      request.post("/getPrTap",pre).then(res =>{
        this.tapList = res.data
      })
    },
    //整表查询
    select(process){
      this.tableDataShow = [];
      request.post("/getlist", {pro: process , id: this.user.id}).then(res =>{
        this.total = res.data.length;
        this.tableData = res.data
        this.getTableDateShow()

      })
      this.getPrTap(process)
    },


    //关键字查询
    /*selectkey(){
      if (parseInt(this.search) >0 ){
        request.post("/connect/selectid",parseInt(this.search)).then(res =>{
          this.tableData = res.data
        })
      } else {
        request.post("/connect/selectkey",this.search).then(res =>{
          this.tableData = res.data
        })
      }
    },
    //重置
    remove(){
      this.search=''
      this.select();
    },*/
    handleSizeChange(val) {
      this.size = val;
      this.getTableDateShow()
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      //console.log(`当前页: ${val}`);
      this.getTableDateShow()
    },
    getTableDateShow(){
      if (this.showZero){
        let _tableData = this.tableData.concat()
        this.total = _tableData.length
        if (this.selectKey !== ""){
          _tableData = _tableData.filter(item => (item.xinghao.includes(this.selectKey) || item.tuzhihao.includes(this.selectKey)))

          this.total = _tableData.length
        } else {
          _tableData = _tableData
        }
        let _pageMax = Math.ceil(this.total/this.size)
        this.currentPage4 = this.currentPage4>_pageMax ? _pageMax :this.currentPage4;
        this.currentPage4 = this.currentPage4 < 1 ? 1 : this.currentPage4
        this.tableDataShow = _tableData.slice((this.currentPage4 - 1) * this.size, this.currentPage4 * this.size);
      } else {
        let _tableData = this.tableData.concat()
        let _table = _tableData.filter(item => item.num === 0)
            this.$nextTick(()=>{
              if (this.selectKey !== ""){
                _table = _table.filter(item => (item.xinghao.includes(this.selectKey) || item.tuzhihao.includes(this.selectKey)))
              } else {
                _table = _table
              }
              this.total = _table.length
              let _pageMax = Math.ceil(this.total/this.size)
              this.currentPage4 = this.currentPage4>_pageMax ? _pageMax :this.currentPage4;
              this.currentPage4 = this.currentPage4 < 1 ? 1 : this.currentPage4
              this.tableDataShow = _table.slice((this.currentPage4 - 1) * this.size, this.currentPage4 * this.size);
        })
      }
    },
    handleClose(done) {
      if (!this.isPhone){
        this.$confirm('确认关闭？')
            .then(_ => {
              done();
            })
            .catch(_ => {});
      } else {
        done();
      }
    },
  }
}

</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}

</style>